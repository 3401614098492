
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  name: string;
  contentable: {
    translation: Record<string, string>;
  };
};

@Component
export default class IframeComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  get url() {
    const contentableUrl = new URL(this.tile.contentable.translation.url);
    let queryParams = "";

    if (contentableUrl.searchParams.has("iframe")) {
      const apiToken = localStorage.getItem("api_token");
      queryParams += "&token=" + apiToken;
    }

    return contentableUrl + queryParams;
  }
}
